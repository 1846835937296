<template>
  <div class="text-start px-4 py-2">
    <div class="d-flex my-2 overflow-auto table-tab">
      <p :class="{ selected: selectedTab === 'ALL' }" @click="changeTab('ALL')">
        {{ $t("borrow.order_status_tab.all") }}
      </p>
      <p
        :class="{ selected: selectedTab === 'prepare' }"
        @click="changeTab('prepare')"
      >
        {{
          isBorrowReturnAdmin
            ? $t("borrow.order_status_tab.need_prepare")
            : $t("borrow.preparing_status")
        }}
      </p>
      <p
        :class="{ selected: selectedTab === 'send' }"
        @click="changeTab('send')"
      >
        {{
          isBorrowReturnAdmin
            ? $t("borrow.order_status_tab.need_send")
            : $t("borrow.need_recieve_status")
        }}
      </p>
      <p
        :class="{ selected: selectedTab === 'return' }"
        @click="changeTab('return')"
      >
        {{ $t("borrow.order_status_tab.need_return") }}
      </p>
      <p
        :class="{ selected: selectedTab === 'success' }"
        @click="changeTab('success')"
      >
        {{ $t("borrow.order_status_tab.success") }}
      </p>
      <p
        :class="{ selected: selectedTab === 'cancel' }"
        @click="changeTab('cancel')"
      >
        {{ $t("borrow.order_status_tab.failed") }}
      </p>
    </div>
    <div class="d-flex justify-content-between align-items-end flex-wrap">
      <div class="d-flex flex-wrap">
        <div class="d-flex flex-column m-2">
          <p style="color: #7c7c7c;">{{ $t("borrow.borrow_filter_start") }}</p>
          <date-picker
            v-model="filter.start_datetime"
            locale="en"
            mode="dateTime"
            is24hr
            :minute-increment="60"
            is-required
            :masks="masks"
            @input="loadAsset"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                readonly
                @click="togglePopover()"
              />
            </template>
          </date-picker>
        </div>
        <div class="d-flex flex-column m-2">
          <p style="color: #7c7c7c;">{{ $t("borrow.borrow_filter_end") }}</p>
          <date-picker
            v-model="filter.end_datetime"
            locale="en"
            mode="dateTime"
            is24hr
            :minute-increment="60"
            :min-date="filter.start_datetime"
            :validHours="hourIsValidEnd"
            is-required
            :masks="masks"
            @input="loadAsset"
          >
            <template v-slot="{ inputValue, togglePopover }">
              <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue"
                readonly
                @click="togglePopover()"
              />
            </template>
          </date-picker>
        </div>
      </div>
      <div class="d-flex align-items-center flex-wrap">
        <img
          v-show="isBorrowReturnAdmin"
          @click="scanQrCode"
          class="mx-2 cursor-pointer"
          src="../../assets/BorrowReturn/borrow-return-qr-scan.svg"
          alt="borrow-return-qr-scan"
        />
        <div>
          <select
            v-model="filter.status"
            class="form-control h-100"
            style="padding: 0.75rem 0.75rem 0.75rem 30px; background-color: #E5E7E7 !important;"
            @change="filterByStatus"
          >
            <option :value="null">ทั้งหมด</option>
            <option
              v-for="status in tabToAllStatusQuery[selectedTab]"
              :key="status"
              :value="status"
              >{{ getStatusText(status) }}</option
            >
          </select>
        </div>
        <div class="d-flex align-items-center">
          <div class="position-relative m-2" style="height: fit-content">
            <input
              v-model="filter.text"
              v-debounce:700ms="loadAsset"
              type="text"
              :placeholder="$t('borrow.search')"
              class="form-control"
              style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important;"
            />
            <img
              class="position-absolute top-50 translate-middle-y"
              style="left: 5px"
              src="../../assets/search-white.svg"
              width="20px"
            />
          </div>
          <button
            class="filter flex-shrink-0"
            type="button"
            id="button-addon2"
            @click="filterModal = true"
          >
            <img src="@/assets/filter-white.svg" />
          </button>
        </div>
      </div>
    </div>
    <manage-order-table
      @interface="getOrderTableInterface"
      :assetData="tableData"
      :otherColumn="otherColumn"
      :page="currentPage"
      :perPage="perPage"
      :rowSelection="'none'"
      :can-change-status="canChangeStatus"
      :can-edit="isBorrowReturnAdmin"
      @change-status="changeBorrowStatus"
      @detail-click="showAssetDetail"
      @note-click="showAssetNote"
      @edit-click="navigateToEditOrder"
    ></manage-order-table>
    <small class="d-block text-end fw-light my-3">{{
      formatedReportDateText
    }}</small>
    <div class="d-flex justify-content-center mt-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="my-table"
        @input="loadAsset"
      ></b-pagination>
    </div>
    <base-dialog
      :showModal="detailModal"
      hideButton
      showCloseButton
      @hide-modal="detailModal = false"
    >
      <div class="mx-3">
        <p class="text-center">{{ $t("home.as_dt") }}</p>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.fixedas_num") }}</p>
          <p>{{ assetDetailInModal.id_fixed_asset }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.as_name") }}</p>
          <p>{{ assetDetailInModal.name }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.as_dt") }}</p>
          <p>{{ assetDetailInModal.description }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.cat") }}</p>
          <p>{{ assetDetailInModal.category }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.sub_cat") }}</p>
          <p>{{ assetDetailInModal.sub_category }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("home.as_loc") }}</p>
          <div class="lh-1">
            <p>
              {{ $t("detailasset.build") }}
              {{ assetDetailInModal.location_building }}
              {{ assetDetailInModal.location_floor }}
            </p>
            <p>
              {{ $t("detailasset.dep") }}
              {{ assetDetailInModal.location_department }}
              {{ $t("detailasset.zone") }}
              {{ assetDetailInModal.location_zone }}
            </p>
          </div>
        </div>
      </div>
    </base-dialog>

    <!-- Confirmation model -->
    <!-- * Need to refactor but, later.... * -->
    <base-dialog
      :showModal="confirmationModal.show"
      @cancel-action="confirmationModal.onCancel"
      @confirm-action="confirmationModal.onConfirm"
      :confirmButtonColor="
        confirmationModal.variant === confirmationModalVariant.SUCCESS
          ? '#017AFE'
          : '#ED1616'
      "
      :confirm-button-text="$t('btn.conf')"
      :cancel-button-text="$t('btn.canc')"
    >
      <div class="mx-3">
        <template
          v-if="
            [
              borrowReturnStatuses.PREPARED,
              borrowReturnStatuses.TO_PREPARE,
            ].includes(confirmationModal.status)
          "
        >
          <img
            class="mx-auto d-block"
            src="../../assets/BorrowReturn/check_confirm.svg"
            alt=""
          />
          <h5
            class="text-center fw-normal my-3"
            v-html="
              $t('borrow.status_change_from_to', {
                statusFrom: $t(
                  `borrow_return_statuses.${confirmationModal.oldStatus}`
                ),
                statusTo: $t(
                  `borrow_return_statuses.${confirmationModal.status}`
                ),
              })
            "
          ></h5>
        </template>
        <template v-else-if="confirmationModal.status === 'CANCEL'">
          <img
            class="mx-auto d-block"
            src="../../assets/BorrowReturn/close_circle_outline.svg"
            alt=""
          />
          <h5 class="text-center fw-normal my-3">
            {{ $t("borrow.borrow_cancel_confirmation") }}
          </h5>
          <div class="asset-info my-3">
            <p class="label text-center">
              {{ $t("borrow.history_notice") }}
            </p>
          </div>
        </template>
        <template v-else-if="confirmationModal.status === 'USING'">
          <img
            class="mx-auto d-block"
            src="../../assets/BorrowReturn/check_confirm.svg"
            alt=""
          />
          <h5 class="text-center fw-normal my-3">
            {{ $t("borrow.send_cancel_confirmation") }}
          </h5>
          <div class="asset-info my-3">
            <p class="label text-center">
              {{ $t("borrow.status_change_notice") }}
            </p>
          </div>

          <asset-card
            :assetId="confirmationModal.selectedAsset.asset.id_asset"
            :assetName="confirmationModal.selectedAsset.asset.name"
            :assetQR="confirmationModal.selectedAsset.asset.id_fixed_asset"
            :assetImageUrl="confirmationModal.selectedAsset.asset.image_url.url"
            :hasAction="false"
          />
        </template>
        <template
          v-else-if="
            [
              borrowReturnStatuses.RETURNED,
              borrowReturnStatuses.RETURNED_DAMAGED,
              borrowReturnStatuses.RETURNED_OVERDUE,
              borrowReturnStatuses.RETURNED_DAMAGED_OVERDUE,
            ].includes(confirmationModal.status)
          "
        >
          <img
            class="mx-auto d-block"
            src="../../assets/BorrowReturn/check_confirm.svg"
            alt=""
          />
          <h5 class="text-center fw-normal my-3">
            {{ $t("borrow.return_confirmation") }}
          </h5>

          <asset-card
            :assetId="confirmationModal.selectedAsset.asset.id_asset"
            :assetName="confirmationModal.selectedAsset.asset.name"
            :assetQR="confirmationModal.selectedAsset.asset.id_fixed_asset"
            :assetImageUrl="confirmationModal.selectedAsset.asset.image_url.url"
            :hasAction="false"
          />
          <div class="asset-info my-3">
            <p class="label text-center">
              {{ $t("borrow.return_note") }}
            </p>
          </div>

          <input
            v-model.trim="confirmationModal.returnNote"
            name="return_note"
            type="text"
            placeholder="-"
            class="form-control"
            style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important;"
          />
        </template>
        <template
          v-else-if="confirmationModal.status === borrowReturnStatuses.REVERSE"
        >
          <img
            class="mx-auto d-block"
            src="../../assets/BorrowReturn/check_confirm.svg"
            alt=""
          />
          <h5 class="text-center fw-normal my-3">
            {{ $t("borrow.reverse_status_to_to_send") }}
          </h5>
        </template>
        <template v-else-if="confirmationModal.status === 'LOST'">
          <img
            class="mx-auto d-block"
            src="../../assets/BorrowReturn/close_circle_outline.svg"
            alt=""
          />
          <h5 class="text-center fw-normal my-3">
            {{ $t("borrow.lost_asset_confirmation") }}
          </h5>

          <asset-card
            :assetId="confirmationModal.selectedAsset.asset.id_asset"
            :assetName="confirmationModal.selectedAsset.asset.name"
            :assetQR="confirmationModal.selectedAsset.asset.id_fixed_asset"
            :assetImageUrl="confirmationModal.selectedAsset.asset.image_url.url"
            :hasAction="false"
          />
          <div class="asset-info my-3">
            <p class="label text-center">
              {{ $t("borrow.note") }}
            </p>
          </div>
          <input
            v-model.trim="confirmationModal.returnNote"
            name="return_note"
            type="text"
            placeholder="-"
            class="form-control"
            style="padding: 0.75rem 0.75rem 0.75rem 30px; background: #E5E7E7 !important;"
          />
        </template>
      </div>
    </base-dialog>

    <base-dialog
      :showModal="noteModal"
      hideButton
      showCloseButton
      @hide-modal="noteModal = false"
    >
      <div class="mx-3">
        <p class="text-center">{{ $t("borrow.note") }}</p>
        <div class="asset-info my-4">
          <p class="label">{{ $t("borrow.borrow_note") }}</p>
          <p>{{ assetDetailInModal.borrow_note }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("borrow.return_note") }}</p>
          <p>{{ assetDetailInModal.return_note }}</p>
        </div>
        <div class="asset-info my-4">
          <p class="label">{{ $t("borrow.status_note") }}</p>
          <p>
            {{
              notAvalilableStatusTranslate(
                assetDetailInModal.not_available_note
              )
            }}
          </p>
        </div>
      </div>
    </base-dialog>
    <asset-filter
      :showModal="filterModal"
      @set-filter="setDefaultFilter"
      @confirm-action="handleFilter"
      @cancel-action="handleClear"
      @hide-modal="filterModal = false"
    ></asset-filter>
  </div>
</template>

<script>
import axios from "axios";
import { authHeader } from "../../store/actions";
import { mapGetters } from "vuex";
import { borrowUrl } from "@/util/backend";
import { featureFlags } from "../../native-app-feature-flag";

import BaseDialog from "../../components/BaseDialog.vue";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import ManageOrderTable from "../../components/Table/BorrowReturn/ManageOrderTable.vue";
import AssetFilter from "../../components/BorrowReturn/AssetFilter.vue";
import AssetCard from "../../components/BorrowReturn/AssetCard.vue";
import { BorrowReturnStatuses } from "../../constants/borrow-return/BorrowReturnStatuses";
import moment from "moment";
import { statusOptionsMixin } from "@/mixin/BorrowReturn/statusOptionsMixin";
import Swal from "sweetalert2";

const ConfirmationModalVariant = {
  SUCCESS: "success",
  DANGER: "danger",
};

export default {
  components: {
    BaseDialog,
    DatePicker,
    ManageOrderTable,
    AssetCard,
    AssetFilter,
  },
  mixins: [statusOptionsMixin],
  data() {
    return {
      orderTableFunction: {
        refreshCells: () => {},
        showLoading: () => {},
        hideLoading: () => {},
      },
      masks: {
        inputDateTime24hr: "DD/MM/YYYY HH:mm",
      },
      selectedTab: "ALL",
      currentPage: 1,
      perPage: 10,
      filterModal: false,
      filter: {
        start_datetime: null,
        end_datetime: null,
        text: null,
        status: null,
        id_category: null,
        id_sub_category: null,
        id_location_building: null,
        id_location_floor: null,
        id_location_department: null,
        id_location_zone: null,
      },
      filterOptions: null,

      tableData: null,
      otherColumn: [],
      totalRows: 0,
      detailModal: false,
      noteModal: false,
      assetDetailInModal: {
        id_fixed_asset: "",
        name: "",
        description: "",
        category: "",
        sub_category: "",
        location_building: "",
        location_zone: "",
        location_floor: "",
        location_department: "",
        borrow_note: "",
        return_note: "",
        not_available_note: "",
      },
      statusChangeModal: false,
      statusChangeAsset: null,

      confirmationModalVariant: ConfirmationModalVariant,
      confirmationModal: {
        show: false,
        status: "",
        selectedAsset: null,
        variant: ConfirmationModalVariant.SUCCESS,
        onConfirm: () => {},
        onCancel: () => {
          this.confirmationModal.show = false;
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      isBorrowReturnAdmin: "isBorrowReturnAdmin",
      getBorrowSettings: "borrow/getBorrowSettings",
    }),

    borrowReturnStatuses: () => BorrowReturnStatuses,
    canChangeStatus() {
      return (
        this.isBorrowReturnAdmin &&
        !this.getBorrowSettings.is_borrow_return_scan
      );
    },
    formatedReportDateText() {
      const now = moment();
      return this.$t("borrow.report_date_format", {
        fullDate: now.format("dddd DD MMMM YYYY"),
        time: now.format("HH:mm A"),
        timeZone: now.format("Z"),
      });
    },
    tabToAllStatusQuery() {
      const status = {
        ALL: this.isBorrowReturnAdmin
          ? [
              BorrowReturnStatuses.TO_PREPARE,
              BorrowReturnStatuses.PREPARED,
              BorrowReturnStatuses.TO_SEND,
              BorrowReturnStatuses.USING,
              BorrowReturnStatuses.TO_RETURN,
              BorrowReturnStatuses.RETURNED,
              BorrowReturnStatuses.RETURNED_DAMAGED,
              BorrowReturnStatuses.OVERDUE,
              BorrowReturnStatuses.RETURNED_OVERDUE,
              BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE,
              BorrowReturnStatuses.LOST,
              BorrowReturnStatuses.NOT_AVAILABLE_PREPARE,
              // BorrowReturnStatuses.NOT_AVAILABLE_SEND,
              BorrowReturnStatuses.CANCEL,
            ]
          : [
              BorrowReturnStatuses.TO_PREPARE,
              BorrowReturnStatuses.TO_SEND,
              BorrowReturnStatuses.TO_RETURN,
              BorrowReturnStatuses.USING,
              BorrowReturnStatuses.OVERDUE,
              BorrowReturnStatuses.RETURNED,
              BorrowReturnStatuses.RETURNED_OVERDUE,
              BorrowReturnStatuses.RETURNED_DAMAGED,
              BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE,
              BorrowReturnStatuses.CANCEL,
              BorrowReturnStatuses.LOST,
            ],
        prepare: this.isBorrowReturnAdmin
          ? [
              BorrowReturnStatuses.TO_PREPARE,
              BorrowReturnStatuses.PREPARED,
              BorrowReturnStatuses.NOT_AVAILABLE_PREPARE,
            ]
          : [
              BorrowReturnStatuses.TO_PREPARE,
              BorrowReturnStatuses.NOT_AVAILABLE_PREPARE,
            ],
        send: [BorrowReturnStatuses.TO_SEND],
        return: [
          BorrowReturnStatuses.TO_RETURN,
          BorrowReturnStatuses.USING,
          BorrowReturnStatuses.OVERDUE,
        ],
        success: [
          BorrowReturnStatuses.RETURNED,
          BorrowReturnStatuses.RETURNED_OVERDUE,
          BorrowReturnStatuses.RETURNED_DAMAGED,
          BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE,
        ],
        cancel: [BorrowReturnStatuses.CANCEL, BorrowReturnStatuses.LOST],
      };
      return status;
    },
  },
  methods: {
    notAvalilableStatusTranslate(value) {
      switch (value) {
        case "DAMAGED":
          return `${this.$t("borrow.not_ready_status")}: ${this.$t(
            "borrow.not_ready.DAMAGED"
          )}`;
        case "LOST":
          return `${this.$t("borrow.not_ready_status")}: ${this.$t(
            "borrow.not_ready.LOST"
          )}`;
        case "OVERDUE":
          return `${this.$t("borrow.not_ready_status")}: ${this.$t(
            "borrow.not_ready.OVERDUE"
          )}`;
        default:
          return "-";
      }
    },
    hourIsValidEnd(hour, dateParts) {
      if (moment(dateParts.date).isSame(this.filter.start_datetime, "d")) {
        return hour > +moment(this.filter.start_datetime).format("H");
      } else {
        return true;
      }
    },
    setDefaultFilter(filterValue) {
      this.filterOptions = filterValue;
    },
    filterByStatus() {
      this.loadAsset();
    },
    handleFilter(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.loadAsset();
    },
    handleClear(filterOptions) {
      this.filterModal = false;
      this.filterOptions = { ...filterOptions };
      this.filter.text = "";
      this.loadAsset();
    },
    getOrderTableInterface(childInterface) {
      this.orderTableFunction = childInterface;
    },
    changeBorrowStatus({ oldStatus, newStatus, borrow_asset_id }) {
      const shouldProvideAssetView = [
        BorrowReturnStatuses.USING,
        BorrowReturnStatuses.RETURNED,
        BorrowReturnStatuses.RETURNED_DAMAGED,
        BorrowReturnStatuses.LOST,
        BorrowReturnStatuses.RETURNED_OVERDUE,
        BorrowReturnStatuses.RETURNED_DAMAGED_OVERDUE,
      ].includes(newStatus);

      if (shouldProvideAssetView) {
        const selectedAsset = this.tableData.find(
          (asset) => asset.id === borrow_asset_id
        );
        console.log(selectedAsset);
        this.confirmationModal.selectedAsset = selectedAsset;
      }
      this.confirmationModal.show = true;

      this.confirmationModal.onConfirm = async () => {
        if (newStatus === this.borrowReturnStatuses.REVERSE) {
          newStatus = this.borrowReturnStatuses.TO_SEND;
        }
        await this.updateBorrowReturnAssetStatus(
          borrow_asset_id,
          newStatus,
          this.confirmationModal?.returnNote || null
        );

        this.confirmationModal.show = false;
      };

      const statusVariant = this.getStatusVariant(newStatus);
      this.confirmationModal.variant = statusVariant;
      this.confirmationModal.status = newStatus;
      this.confirmationModal.oldStatus = oldStatus;
    },
    async updateBorrowReturnAssetStatus(
      borrow_asset_id,
      status,
      return_note = null
    ) {
      try {
        let payload =
          status === "LOST"
            ? {
                status: status,
                note_lost: return_note,
              }
            : {
                status,
                return_note,
              };
        await axios.post(
          `${borrowUrl()}asset/id/${borrow_asset_id}/status`,
          payload,
          authHeader()
        );
        await this.loadAsset();
      } catch (err) {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: this.$t("borrow.edit_borrow_failed.title"),
          text: this.$t(`borrow.edit_borrow_failed.${err.response.data.error}`),
        });
      }
    },
    getStatusVariant(statusText) {
      switch (statusText) {
        case BorrowReturnStatuses.USING:
          return ConfirmationModalVariant.SUCCESS;

        case BorrowReturnStatuses.RETURNED:
          return ConfirmationModalVariant.SUCCESS;

        case BorrowReturnStatuses.RETURNED_DAMAGED:
          return ConfirmationModalVariant.SUCCESS;

        case BorrowReturnStatuses.CANCEL:
          return ConfirmationModalVariant.DANGER;

        case BorrowReturnStatuses.LOST:
          return ConfirmationModalVariant.DANGER;

        default:
          return ConfirmationModalVariant.SUCCESS;
      }
    },
    cancelStatusChange() {
      this.orderTableFunction.refreshCells({ force: true });
      this.statusChangeAsset = null;
      this.statusChangeModal = false;
    },
    confirmStatusChange() {
      this.statusChangeAsset = null;
      this.statusChangeModal = false;
    },
    changeTab(tabName) {
      this.selectedTab = tabName;
      if (tabName === "ALL") this.$router.push({ name: this.$route.name });
      else
        this.$router.push({
          name: this.$route.name,
          query: {
            tab: tabName,
          },
        });
    },
    showAssetDetail(assetDetail) {
      this.setAssetDetailInModal(assetDetail);
      this.detailModal = true;
    },
    showAssetNote(assetDetail) {
      this.setAssetDetailInModal(assetDetail);
      this.noteModal = true;
    },
    setAssetDetailInModal(assetDetail) {
      this.assetDetailInModal = {
        id_fixed_asset: assetDetail.asset.id_fixed_asset,
        name: assetDetail.asset.name,
        description: assetDetail.asset?.description || "-",
        category: assetDetail.asset?.category
          ? assetDetail.asset.category.name
          : "-",
        sub_category: assetDetail.asset?.sub_category
          ? assetDetail.asset.sub_category.name
          : "-",
        location_building: assetDetail.asset?.location_building
          ? assetDetail.asset.location_building.name
          : "-",
        location_zone: assetDetail.asset?.location_zone
          ? assetDetail.asset.location_zone.name
          : "-",
        location_floor: assetDetail.asset?.location_floor
          ? assetDetail.asset.location_floor.name
          : "-",
        location_department: assetDetail.asset?.location_department
          ? assetDetail.asset.location_department.name
          : "-",
        borrow_note: assetDetail?.borrow_note || "-",
        return_note: assetDetail?.return_note || "-",
        not_available_note: assetDetail?.not_available_note || "-",
      };
    },
    async loadAsset() {
      this.orderTableFunction.showLoading();
      const id_company = await this.$store.dispatch("getCompanyIdCookie");
      this.id_company = id_company;

      // This should tell you which status query will be used in the API call in each tab

      /* NOTE สำหรับการแก้เงื่อนไขในการส่ง Array ไป Query */
      let status = this.filter.status
        ? [this.filter.status]
        : this.tabToAllStatusQuery[this.selectedTab];

      const additionalStatus = [];
      if (
        !this.isBorrowReturnAdmin &&
        ["ALL", "prepare"].includes(this.selectedTab)
      ) {
        additionalStatus.push(BorrowReturnStatuses.PREPARED);
      }

      // เพื่อให้สถานะ ไม่พร้อมใช้งาน query ออกมาทั้ง NOT_AVAILABLE_PREPARE และ NOT_AVAILABLE_SEND
      if (status.includes(BorrowReturnStatuses.NOT_AVAILABLE_PREPARE)) {
        additionalStatus.push(BorrowReturnStatuses.NOT_AVAILABLE_SEND);
      }

      /* สำหรับการแก้เงื่อนไขในการส่ง Array ไป Query */

      const response = await axios.get(
        `${borrowUrl()}asset/list/request/company/id/${id_company}`,
        {
          params: {
            ...this.filter,
            ...this.filterOptions,
            start_datetime: this.filter.start_datetime
              ? moment(this.filter.start_datetime)
                  .minute(0)
                  .second(0)
                  .millisecond(0)
                  .toISOString()
              : undefined,
            end_datetime: this.filter.end_datetime
              ? moment(this.filter.end_datetime)
                  .minute(0)
                  .second(0)
                  .millisecond(0)
                  .toISOString()
              : undefined,
            status: [...status, ...additionalStatus],
            page: this.currentPage,
            limit: this.perPage,
          },
          ...authHeader(),
        }
      );
      const data = response.data;
      this.tableData = data.results;
      this.totalRows = data.item_count;
      this.orderTableFunction.hideLoading();
    },
    navigateToEditOrder({ id }) {
      this.$router.push({
        name: "edit-order",
        params: {
          borrow_return_asset_id: id,
        },
      });
    },
    scanQrCode() {
      if (featureFlags.scan) {
        window.sendMessage("scan", {
          mode: "borrowReturn",
        });
      } else {
        this.$router.push({ name: "borrow-qr-scanner" });
      }
    },
  },

  created() {
    this.selectedTab = this.$route.query.tab || "ALL";
    this.loadAsset();
  },
};
</script>

<style lang="scss" scoped>
.table-tab {
  font-size: 20px;
  p {
    padding: 0 1rem;
    border-right: 1px solid black;
    color: #7c7c7c;
    cursor: pointer;
    white-space: nowrap;
    &.selected {
      color: #017afe;
    }
    &:last-child {
      border-right: none;
    }
  }
}

#dropdown-1 {
  min-width: 200px;
  ::v-deep button {
    outline: none !important;
    background: #f4f4f4 !important;
    border: 2px solid #d2d2d2;
    border-radius: 8px;
    color: #000;
    border-radius: 8px;
  }
  p {
    margin: 0px;
    padding: 5px 0;
    font-size: 14px;
  }
}

.filter {
  border-radius: 10px 10px 10px 10px !important;
  background: linear-gradient(180deg, #007afe 0%, #013f81 100%) !important;
  border: none;
  width: 40px;
  height: 40px;
  img {
    padding: 0.3rem;
    width: 40px;
  }
}

.asset-info {
  .label {
    color: #7c7c7c;
    font-size: 15px;
  }
}
</style>
